import Toastify from "toastify-js"

/**
 * errorToast shows toast with red background
 * @param  {object} options {message: '', duration: ''}
 */
export const errorToast = options => {
  generalToast({
    message: options?.message || "An error has occurred!",
    style: {
      color: "white",
      background: "#b91c1c",
    },
  })
}

/**
 * infoToast shows toast with gray background
 * @param  {object} options {message: '', duration: ''}
 */
export const infoToast = options => {
  generalToast({
    message: options?.message || "",
    style: {
      color: "white",
      background: "#475569",
    },
  })
}

/**
 * successToast shows toast with green background
 * @param  {object} options {message: '', duration: ''}
 */
export const successToast = options => {
  generalToast({
    message: options?.message || "Success !",
    style: {
      color: "white",
      background: "#15803d",
    },
  })
}

const generalToast = options => {
  try {
    Toastify({
      text: typeof options === "string" ? options : options.message,
      duration: 5000,
      close: true,
      gravity: "top",
      position: "right",
      stopOnFocus: true,
      style: options.style,
    }).showToast()
  } catch (error) {
    console.error(error)
  }
}
